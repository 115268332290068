import { Routes } from '@angular/router';
import {
  AuthGuard,
  AutologComponent,
  LoginComponent,
  LoginSSOComponent,
  LogoutComponent,
  MailAuthComponent,
  NotFoundComponent,
  ResetPasswordComponent,
  ResetPasswordFormComponent,
  ResetPasswordMailSentComponent,
  ResetPasswordPincodeComponent,
  SignupComponent,
  SsoErrorComponent,
  TomAuthComponent,
  UpdateAvailableComponent,
} from 'common';
import { StaticPageComponent } from 'projects/frontoffice/src/app/pages/static-page/static-page.component';
import { AcademyComponent } from './academy/academy.component';
import { SupportComponent } from './admins/support/support.component';
import { BackLayoutComponent } from './back-layout/back-layout.component';

export const routes: Routes = [
  {
    path: '',
    component: BackLayoutComponent,
    canActivateChild: [AuthGuard],

    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./admins/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'campaign/survey-stats/:id/:slug',
        loadChildren: () => import('./admins/campaign/survey-stats/survey-stats.module').then((m) => m.SurveyStatsModule),
      },
      {
        path: 'campaign/survey-stats/:id/:slug',
        loadChildren: () => import('./admins/campaign/survey-stats/survey-stats.module').then((m) => m.SurveyStatsModule),
      },
      {
        path: 'campaign',
        loadChildren: () => import('./admins/campaign/campaign.module').then((m) => m.CampaignModule),
      },
      {
        path: 'user',
        loadChildren: () => import('./admins/user/user-back.module').then((m) => m.UserBackModule),
      },
      {
        path: 'certification',
        loadChildren: () => import('./admins/certification/certification-back.module').then((m) => m.CertificationBackModule),
      },
      {
        path: 'modality',
        loadChildren: () => import('./admins/certification-modality/certification-modality-back.module').then((m) => m.CertificationModalityBackModule),
      },
      {
        path: 'configuration',
        loadChildren: () => import('./admins/configuration/configuration-back.module').then((m) => m.ConfigurationBackModule),
      },
      {
        path: 'formation',
        loadChildren: () => import('./admins/mesure-impact-formation/mif-back.module').then((m) => m.MesureImpactFormationBackModule),
      },
      {
        path: 'event',
        loadChildren: () => import('./admins/mif-events/mif-events-back.module').then((m) => m.MifEventsBackModule),
      },
      {
        path: 'r',
        loadChildren: () => import('./admins/repositories/repository.module').then((m) => m.RepositoryModule),
      },
      {
        path: 'restitution',
        loadChildren: () => import('./admins/bilan/restitution-back.module').then((m) => m.RestitutionBackModule),
      },
      {
        path: 'network',
        loadChildren: () => import('./admins/network/network-back.module').then((m) => m.NetworkBackModule),
      },

      { path: 'support', component: SupportComponent },

      {
        path: 'feedback',
        loadChildren: () => import('./admins/fbs/fbs-back.module').then((m) => m.FbsBackModule),
      },

      {
        path: 'opinion',
        loadChildren: () => import('./admins/opinion/opinion-back.module').then((m) => m.OpinionBackModule),
      },
      {
        path: 'ema',
        loadChildren: () => import('./admins/ema/ema-back.module').then((m) => m.EmaBackModule),
      },
      {
        path: 'verbatims',
        loadChildren: () => import('./admins/verbatim/verbatim.module').then((m) => m.VerbatimModule),
      },

      { path: 'gdpr', component: StaticPageComponent, data: { backoffice: true } },
      { path: 'academy', component: AcademyComponent },
    ],
  },
  { path: 'login', component: LoginComponent, data: { backoffice: true } },
  { path: 'sso', component: LoginSSOComponent, data: { backoffice: true } },
  { path: 'sso/:id', component: LoginSSOComponent, data: { backoffice: true } },
  { path: 'mail-auth', component: MailAuthComponent, data: { backoffice: true } },
  { path: 'sso-error/:error/:email', component: SsoErrorComponent },
  { path: 'sso-error/:error', component: SsoErrorComponent },
  { path: 'sso-error', component: SsoErrorComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'signup', component: SignupComponent, data: { backoffice: true } },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'reset-password-pincode', component: ResetPasswordPincodeComponent },
  { path: 'reset-password/confirm', component: ResetPasswordMailSentComponent },
  { path: 'reset-password/:token', component: ResetPasswordFormComponent },
  {
    path: 'autolog',
    component: AutologComponent,
  },
  {
    path: 'tom-auth',
    component: TomAuthComponent,
  },
  { path: 'update', component: UpdateAvailableComponent },
  { path: 'update/:err', component: UpdateAvailableComponent },
  { path: 'logout', component: LogoutComponent },
  //{ path: 'opinion', component: OpinionQuestionListComponent },
  { path: '**', component: NotFoundComponent },
];
